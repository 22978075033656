<template>
  <v-row align="center" justify="center" class="pt-8">
    <v-col cols="12" class="text-center" align-self="center">
      <v-avatar size="128" color="error">
        <v-icon class="pa-8" dark> {{ mdiAlert }} </v-icon>
      </v-avatar>
      <div class="text-h3 my-8">This page does not exist!</div>
      <v-btn x-large to="/" color="accent" class="pa-5 py-5 mt-8">
        Go Home
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mdiAlert } from "@mdi/js";
export default {
  data() {
    return {
      mdiAlert,
    };
  },
};
</script>
